@import '../../../styles/common';

.add-patient-container {
  .add-patient-card {
    @extend .card;

    .add-patient-h2-wrapper {
      @extend .header-wrapper;

      margin-bottom: 15px;
    }
  }
}
