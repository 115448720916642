@import 'common';
@import '~antd/dist/antd.css';

td {
  max-width: 200px;
  white-space: pre-wrap;
}

.highlighted-text {
  background-color: #ffc069;
  padding: 0;
}

.ant-form-rtl {
  .ant-form-item-control-input {
    .ant-input-number-suffix,
    .ant-input-textarea-suffix {
      left: 0;
      margin-left: 10px;
      margin-right: 4px;
      right: auto;
    }
  }

  .ant-form-item-feedback-icon {
    margin-right: 8px;
  }

  .ant-form-item-has-feedback {
    .ant-picker,
    .ant-select-selector {
      padding-left: 11px;
    }

    .ant-input-affix-wrapper {
      .ant-input-suffix {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
