@import 'mixins';

.flex {
  display: flex;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.justify-between {
  justify-content: space-between;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.height-100 {
  height: 100%;
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

.text-right {
  text-align: right;
}

.negative-number {
  direction: ltr;
  display: inline-block;
  text-align: right;
}

.card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px 50px 30px;
  @include sm {
    padding: 20px 20px 30px;
  }

  @include xs {
    padding: 16px 10px;
  }
}

.header-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  h2 {
    color: #979797;
    font-size: 20px;
    font-weight: 400;
  }
}

.ml-6 {
  margin-left: 20px;
}

.clickable {
  cursor: pointer;
}
