@use 'sass:math';
@import '../../styles/variables';

.layout-container {
  height: 100vh;

  .content {
    overflow-y: auto;
    max-height: calc(100% - #{$header-height});
    padding: $content-padding;
    position: relative;
  }

  .logo {
    background: rgba(255, 255, 255, 0.2);
    height: $logo-height;
    margin: math.div(($header-height - $logo-height), 2);
  }

  .ant-menu-item:first-child {
    margin-top: 0;
  }
}
