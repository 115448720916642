@import '../../../styles/colors';

.patients-container {
  height: 100%;

  .actions-container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    overflow-x: auto;
    scrollbar-width: none; /* Firefox */
    width: 100%;

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  .diagnoses-container {
    max-height: 50px;
    -ms-overflow-style: none;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .credit {
    color: $green;
  }

  .debt {
    color: $red;
    direction: ltr;
    display: inline-block;
  }
}

.email-check:active {
  opacity: 0.5;
}
