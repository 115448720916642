@import '../../styles/common';
@import '../../styles/variables';

.login-container {
  background-color: #eff1f5;
  height: 100vh;

  .login-card {
    @extend .card;

    padding: 30px 50px 40px;

    .login-error {
      margin-bottom: 16px;
    }
  }
}
