@import 'variables';

@mixin xs {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}
// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl}) {
    @content;
  }
}
