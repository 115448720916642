@import '../../styles/variables';

.topbar-container.header {
  align-items: center;
  display: flex;
  height: $header-height;
  justify-content: space-between;
  padding: 0;

  .right {
    .trigger {
      color: #fff;
      cursor: pointer;
      font-size: 18px;
      line-height: 64px;
      padding-right: 24px;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }
  }

  .center {
    color: #e6f7ff;
  }

  .left {
    align-items: center;
    display: flex;
    padding: 0 20px;

    .badge {
      font-size: 16px;
    }

    .reminder-icon {
      color: #fff;
      padding: 4px;
    }

    .header-user-avatar {
      background-color: #487dae;
      cursor: pointer;
    }
  }

  .action {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: $header-height;
    padding: 0 12px;
    transition: all 0.3s;

    > span {
      color: #fff;
    }

    &:hover {
      background: #1890ff;
    }

    &.ant-dropdown-open {
      background: #1890ff;
    }
  }
}

.reminder-popover {
  position: relative;
  width: 336px;

  .list {
    max-height: 400px;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .item {
      cursor: pointer;
      overflow: hidden;
      padding-left: 24px;
      padding-right: 24px;
      transition: all 0.3s;

      .meta {
        width: 100%;
      }

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        background: #e6f7ff;
      }

      .title {
        margin-bottom: 6px;
      }

      .description {
        font-size: 12px;
        margin-bottom: 4px;
      }

      .date {
        font-size: 12px;
      }
    }
  }

  .bottom-bar {
    border-radius: 0 0 2px 2px;
    border-top: 1px solid #f0f0f0;
    color: rgba(0, 0, 0, 0.65);
    height: 46px;
    line-height: 46px;
    text-align: center;
    transition: all 0.3s;

    div {
      cursor: pointer;
      display: inline-block;
      transition: all 0.3s;
      user-select: none;
      width: 50%;

      &:only-child {
        width: 100%;
      }

      &:not(:only-child):last-child {
        border-left: 1px solid #f0f0f0;
      }
    }
  }
}

.avatar-dropdown {
  .account {
    .avatar {
      background: #ffffffd9;
      color: #1890ff;
      margin: 20px 0 20px 8px;
    }
  }
}
