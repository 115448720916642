$header-height: 54px;
$content-padding: 24px;
$logo-height: 32px;

// Small tablets and large smartphones (landscape view)
$screen-sm: 576px;

// Small tablets (portrait view)
$screen-md: 768px;

// Tablets and small desktops
$screen-lg: 992px;

// Large tablets and desktops
$screen-xl: 1200px;
