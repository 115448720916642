@import '../../../styles/common';

.add-transaction-container {
  .add-transaction-card {
    @extend .card;

    .add-transaction-h2-wrapper {
      @extend .header-wrapper;

      margin-bottom: 15px;
    }
  }
}
