@import '../../../styles/common';

.report-container {
  .report-card {
    @extend .card;
  }
}

.income {
  color: #008000;
}

.expenditure {
  color: #f00;
}
