.treatment-type {
  font-weight: 600;

  .diet {
    color: #333;
  }

  .reflexology {
    color: #333;
  }
}
