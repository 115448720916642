@import '../../../styles/variables';
@import '../../../styles/common';

$next-prev-container-height: 70px;

.right-col {
  max-height: calc(100vh - #{$content-padding} - #{$header-height});
  padding-left: $content-padding;

  .steps {
    margin-bottom: 20px;
  }
}

.treatment-form {
  max-height: calc(100vh - #{$content-padding} - #{$header-height} - #{$next-prev-container-height});
  overflow-y: auto;

  h2 {
    text-align: center;
  }

  .form-card {
    @extend .card;
    margin-bottom: 24px;
  }

  .ant-back-top {
    bottom: 85px;
  }
}

.save-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 20px;
  margin-top: auto;

  button {
    margin-top: 8px;
  }
}

.next-prev-container {
  align-items: center;
  display: flex;
  height: $next-prev-container-height;
}
