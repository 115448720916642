@import '../../../styles/colors';

.amount {
  &-income {
    color: $green;
    font-weight: bold;
  }

  &-expenditure {
    color: $red;
    font-weight: bold;
  }
}
